<template>
    <div class="loader_area no_sidebar" v-if="contactCurrentProgressLoader" :style="`--var-element-bg-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}; --var-element-text-color: ${progressTracking.public_settings && progressTracking.public_settings.element_text_color && (progressTracking.public_settings.element_text_color.toLowerCase() != '#fff' || progressTracking.public_settings.element_text_color.toLowerCase() != '#ffffff') ? progressTracking.public_settings.element_text_color : '#121525'}; --var-element-light-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}20;`"><quote-loader/></div>
    <div class="report_section" v-else :style="`--var-element-bg-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}; --var-element-text-color: ${progressTracking.public_settings && progressTracking.public_settings.element_text_color && (progressTracking.public_settings.element_text_color.toLowerCase() != '#fff' || progressTracking.public_settings.element_text_color.toLowerCase() != '#ffffff') ? progressTracking.public_settings.element_text_color : '#121525'}; --var-element-light-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}20;`">
        <div v-if="progressTracking.is_classic">
            <div class="report_container" v-if="submittedGoal">
                <div class="report_card">
                    <div class="header">
                        <div class="user_info">
                            <img :src="contact.photo" alt="">
                            <div>
                                <h4>{{ contact.name }}</h4>
                                <h3>Started Tracking <span>{{ moment(currentProgress.started_time && currentProgress.started_time.date ? currentProgress.started_time.date : '').format('ll') }}</span></h3>
                            </div>
                        </div>
                        <div class="submission">
                            <!-- <h4>{{ histories.length }} / {{ currentProgress.compliance ? currentProgress.compliance.occurrences : 0 }}</h4> -->
                            <h4>{{ filterGoalLength(completed) }} / {{ filterGoalLength(completed) + filterGoalLength(missed) }}</h4>
                            <h3>Submissions</h3>
                        </div>
                    </div>
                </div>
                <div class="section_header mt-5">
                    <h2>Progress</h2>
                </div>
                <ul class="tracking_list">
                    <li>
                        <h4>Participation Score: <span>{{ filterGoalLength(completed) ? Math.round((filterGoalLength(completed) * 100) / filterGoalLength(histories)) : 0 }}%</span></h4>
                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                            <div class="inner_circle">
                                <div class="score" style="transform: scale(0.8);">{{ filterGoalLength(completed) ? Math.round((filterGoalLength(completed) * 100) / filterGoalLength(histories)) : 0 }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="115" :stroke-dashoffset="115 - ((filterGoalLength(completed) ? Math.round((filterGoalLength(completed) * 100) / filterGoalLength(histories)) : 0) * 1.15)"/>
                            </svg>
                        </div>
                        <!-- <h4>Participation Score: <span>{{ filterGoalLength(completed) ? Math.round((filterGoalLength(completed) * 100) / filterGoalLength(histories)) : 0 }}%</span></h4>
                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                            <div class="inner_circle">
                                <div class="score" style="transform: scale(0.8);">{{ currentProgress.compliance ? currentProgress.compliance.percentage : 0 }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202 - (currentProgress.compliance ? currentProgress.compliance.percentage * 2 : 0)"/>
                            </svg>
                        </div> -->
                    </li>
                    <li>
                        <h4>Progress Score: <span>{{ currentProgress.overall_progress ? currentProgress.overall_progress.percentage : 0 }}%</span></h4>
                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                            <div class="inner_circle">
                                <div class="score" style="transform: scale(0.8);">{{ currentProgress.overall_progress ? currentProgress.overall_progress.percentage : 0 }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202 - (currentProgress.overall_progress ? currentProgress.overall_progress.percentage * 2 : 0)"/>
                            </svg>
                        </div>
                    </li>
                </ul>
                <div class="progress_tbl">
                    <div class="section_header mt-5" v-if="progressTracking.data_settings && progressTracking.data_settings.has_weight">
                        <h2>Weight</h2>
                    </div>
                    <div class="result_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.has_weight">
                        <table>
                            <thead>
                                <tr>
                                    <th>Start</th>
                                    <th>Current</th>
                                    <th>Goal</th>
                                    <th>Percent of Goal</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><label>Weight:</label> {{ currentProgress.weight ? currentProgress.weight.start : 0 }}</td>
                                    <td><label>Weight:</label> {{ currentProgress.weight ? currentProgress.weight.current : 0 }}</td>
                                    <td><label>Weight:</label> {{ currentProgress.weight ? currentProgress.weight.goal : 0 }}</td>
                                    <td>
                                        <h6>{{ currentProgress.weight ? currentProgress.weight.percentage : 0 }}%</h6>
                                        <div class="status"><span :style="`width: ${currentProgress.weight ? currentProgress.weight.percentage : 0}%;`"></span></div>
                                    </td>
                                    <td>
                                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                            <div class="inner_circle">
                                                <div class="score" style="transform: scale(0.8);">{{ currentProgress.weight ? currentProgress.weight.percentage : 0 }}<span>%</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="(202 - (currentProgress.weight ? currentProgress.weight.percentage * 2 : 0))"/>
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="section_header mt-5" v-if="progressTracking.data_settings && progressTracking.data_settings.has_body_fat">
                        <h2>Body Fat</h2>
                    </div>
                    <div class="result_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.has_body_fat">
                        <table>
                            <thead>
                                <tr>
                                    <th>Start</th>
                                    <th>Current</th>
                                    <th>Goal</th>
                                    <th>Percent of Goal</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><label>Body Fat:</label> {{ currentProgress.body_fat ? currentProgress.body_fat.start : 0 }}</td>
                                    <td><label>Body Fat:</label> {{ currentProgress.body_fat ? currentProgress.body_fat.current : 0 }}</td>
                                    <td><label>Body Fat:</label> {{ currentProgress.body_fat ? currentProgress.body_fat.goal : 0 }}</td>
                                    <td>
                                        <h6>{{ currentProgress.body_fat ? currentProgress.body_fat.percentage : 0 }}%</h6>
                                        <div class="status"><span :style="`width: ${currentProgress.body_fat ? currentProgress.body_fat.percentage : 0}%;`"></span></div>
                                    </td>
                                    <td>
                                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                            <div class="inner_circle">
                                                <div class="score" style="transform: scale(0.8);">{{ currentProgress.body_fat ? currentProgress.body_fat.percentage : 0 }}<span>%</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202 - (currentProgress.body_fat ? currentProgress.body_fat.percentage *2 : 0)"/>
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="section_header mt-5" v-if="progressTracking.data_settings && progressTracking.data_settings.has_circumference">
                        <h2>Circumference</h2>
                    </div>
                    <div class="result_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.has_circumference">
                        <table>
                            <thead>
                                <tr>
                                    <th>Start</th>
                                    <th>Current</th>
                                    <th>Goal</th>
                                    <th>Percent of Goal</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(circumference, title) of currentProgress.circumference" :key="title">
                                    <td><label class="capitalize">{{ title.replace('_', ' ') }}:</label> {{ circumference.start }}</td>
                                    <td><label class="capitalize">{{ title.replace('_', ' ') }}:</label> {{ circumference.current }}</td>
                                    <td><label class="capitalize">{{ title.replace('_', ' ') }}:</label> {{ circumference.goal }}</td>
                                    <td>
                                        <h6>{{ circumference.percentage }}%</h6>
                                        <div class="status"><span :style="`width: ${circumference.percentage}%;`"></span></div>
                                    </td>
                                    <td>
                                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                            <div class="inner_circle">
                                                <div class="score" style="transform: scale(0.8);">{{ circumference.percentage }}<span>%</span></div>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202 - ( circumference.percentage * 2 )"/>
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="progressTracking.data_settings && progressTracking.data_settings.has_custom_questions && currentProgress.assessments">
                        <template v-for="(assessments, sectionTitle) of currentProgress.assessments" :key="sectionTitle">
                            <div class="section_header mt-5">
                                <h2>{{ sectionTitle }}</h2>
                            </div>
                            <div class="result_wpr">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Start</th>
                                            <th>Current</th>
                                            <th>Goal</th>
                                            <th>Percent of Goal</th>
                                            <th>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(assessment, title) of assessments" :key="title">
                                            <td><label class="capitalize">{{ title.replace('_', ' ') }}:</label> {{ assessment.start }}</td>
                                            <td><label class="capitalize">{{ title.replace('_', ' ') }}:</label> {{ assessment.current }}</td>
                                            <td><label class="capitalize">{{ title.replace('_', ' ') }}:</label> {{ assessment.goal }}</td>
                                            <td>
                                                <h6>{{ assessment.percentage }}%</h6>
                                                <div class="status"><span :style="`width: ${assessment.percentage}%;`"></span></div>
                                            </td>
                                            <td>
                                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                                    <div class="inner_circle">
                                                        <div class="score" style="transform: scale(0.8);">{{ assessment.percentage }}<span>%</span></div>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                        <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202 - ( assessment.percentage * 2 )"/>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="section_header mt-5" v-if="progressTracking.data_settings && progressTracking.data_settings.has_progress_pics && Object.values(currentProgress.images).length">
                    <h2>Picture</h2>
                </div>
                <ul class="picture_section" v-if="progressTracking.data_settings && progressTracking.data_settings.has_progress_pics && Object.values(currentProgress.images).length">
                    <li v-if="progressTracking.data_settings.photo_view && progressTracking.data_settings.photo_view.front && currentProgress.images.front">
                        <ul class="section_wpr">
                            <li>
                                <div class="section_title">
                                    <h4>Starting Front</h4>
                                </div>
                                <div class="img_viewer">
                                    <img @click="previewImage(currentProgress.images ? currentProgress.images.front.start : '')" :src="currentProgress.images ? currentProgress.images.front.start : require('@/assets/images/thumb/default-cover-2-3.svg')">
                                    <button type="button" class="lightbox_btn" @click="previewImage(currentProgress.images ? currentProgress.images.front.start : '')"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="currentProgress.images ? currentProgress.images.front.start : ''" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </li>
                            <li>
                                <div class="section_title">
                                    <h4>Current Front</h4>
                                </div>
                                <div class="img_viewer">
                                    <img @click="previewImage(currentProgress.images ? currentProgress.images.front.current : '')" :src="currentProgress.images ? currentProgress.images.front.current : require('@/assets/images/thumb/default-cover-2-3.svg')">
                                    <button type="button" class="lightbox_btn" @click="previewImage(currentProgress.images ? currentProgress.images.front.current : '')"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="currentProgress.images ? currentProgress.images.front.current : ''" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li v-if="progressTracking.data_settings.photo_view && progressTracking.data_settings.photo_view.side && currentProgress.images.side">
                        <ul class="section_wpr">
                            <li>
                                <div class="section_title">
                                    <h4>Starting Side</h4>
                                </div>
                                <div class="img_viewer">
                                    <img @click="previewImage(currentProgress.images ? currentProgress.images.side.start : '')" :src="currentProgress.images ? currentProgress.images.side.start : require('@/assets/images/thumb/default-cover-2-3.svg')">
                                    <button type="button" class="lightbox_btn" @click="previewImage(currentProgress.images ? currentProgress.images.side.start : '')"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="currentProgress.images ? currentProgress.images.side.start : ''" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </li>
                            <li>
                                <div class="section_title">
                                    <h4>Current Side</h4>
                                </div>
                                <div class="img_viewer">
                                    <img @click="previewImage(currentProgress.images ? currentProgress.images.side.current : '')" :src="currentProgress.images ? currentProgress.images.side.current : require('@/assets/images/thumb/default-cover-2-3.svg')">
                                    <button type="button" class="lightbox_btn" @click="previewImage(currentProgress.images ? currentProgress.images.side.current : '')"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="currentProgress.images ? currentProgress.images.side.current : ''" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li v-if="progressTracking.data_settings.photo_view && progressTracking.data_settings.photo_view.back && currentProgress.images.back">
                        <ul class="section_wpr">
                            <li>
                                <div class="section_title">
                                    <h4>Starting Back</h4>
                                </div>
                                <div class="img_viewer">
                                    <img @click="previewImage(currentProgress.images ? currentProgress.images.back.start : '')" :src="currentProgress.images ? currentProgress.images.back.start : require('@/assets/images/thumb/default-cover-2-3.svg')">
                                    <button type="button" class="lightbox_btn" @click="previewImage(currentProgress.images ? currentProgress.images.back.start : '')"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="currentProgress.images ? currentProgress.images.back.start : ''" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </li>
                            <li>
                                <div class="section_title">
                                    <h4>Current Back</h4>
                                </div>
                                <div class="img_viewer">
                                    <img @click="previewImage(currentProgress.images ? currentProgress.images.back.current : '')" :src="currentProgress.images ? currentProgress.images.back.current : require('@/assets/images/thumb/default-cover-2-3.svg')">
                                    <button type="button" class="lightbox_btn" @click="previewImage(currentProgress.images ? currentProgress.images.back.current : '')"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="currentProgress.images ? currentProgress.images.back.current : ''" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <vue-easy-lightbox :visible="previewImagePanel" :index="selectdImageIndex" :imgs="availableImages" @hide="previewImagePanel = false"></vue-easy-lightbox>
                <!-- <div class="section_header mt-5">
                    <h2>History</h2>
                </div>
                <div class="result_wpr">
                    <div class="history_tbl">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    
                                    <th>Status</th>
                                    <th>Entry</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(history, h) of histories" :key="h">
                                    <td>{{ moment.utc(history.created_at).local().format('ll') }}</td>
                                   
                                    <td v-if="history.is_goal">Submitted Goals</td>
                                    <td v-else>
                                        <span v-if="history.is_submitted && history.is_edited">Edited After Submission</span>
                                        <span v-else-if="history.is_submitted && moment.duration(moment(history.updated_at).diff(history.scheduled_at)).asHours() >= 2">Submitted Late</span>
                                        <span v-else-if="history.is_submitted">Submitted On Time</span>
                                        <span v-else-if="!history.is_submitted && moment.duration(moment().diff(history.scheduled_at)).asHours() >= 6" >Missed</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td><button @click="viewHistory(history)"><i class="fa fa-search"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
                <div class="content_card_wpr show">
                    <div class="section_header mt-5">
                        <h2>Completed <label>{{ filterGoalLength(completed) }}</label></h2>
                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                            <div class="inner_circle">
                                <div class="score" style="transform: scale(0.8);">{{ filterGoalLength(completed) ? Math.round((filterGoalLength(completed) * 100) / filterGoalLength(histories)) : 0 }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="115" :stroke-dashoffset="115 - (filterGoalLength(completed) ? (Math.round((filterGoalLength(completed) * 100) / filterGoalLength(histories)) * 1.15) : 0)"/>
                            </svg>
                        </div>
                    </div>
                    <div class="result_wpr">
                        <div class="history_tbl">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="wx-200">Date</th>
                                        <!-- <th v-if="progressTracking.data_settings.has_weight || progressTracking.data_settings.has_body_fat || progressTracking.data_settings.has_circumference">Week</th> -->
                                        <th>Status</th>
                                        <th class="wx-75">Entry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(complete, h) of completed" :key="h">
                                        <td>{{ moment.utc(complete.created_at).local().format('MM/DD/YYYY') }}</td>
                                        <!-- <td v-if="progressTracking.data_settings.has_weight || progressTracking.data_settings.has_body_fat || progressTracking.data_settings.has_circumference">Week {{ history.week ? history.week : 1 }}</td> -->
                                        <td v-if="complete.is_goal">Submitted Goals</td>
                                        <td v-else>
                                            <span v-if="complete.is_submitted && complete.is_edited">Edited After Submission</span>
                                            <span v-else-if="complete.is_submitted && moment.duration(moment(complete.updated_at).diff(complete.scheduled_at)).asHours() >= 23">Submitted Late</span>
                                            <span v-else-if="complete.is_submitted">Submitted On Time</span>
                                            <span v-else-if="!complete.is_submitted && moment.duration(moment().diff(complete.scheduled_at)).asHours() >= 6" >Missed</span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <button @click="viewHistory(complete)"><i class="fa fa-search"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="content_card_wpr show">
                    <div class="section_header mt-5">
                        <h2>Missed <label>{{ filterGoalLength(missed) }}</label></h2>
                        <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                            <div class="inner_circle">
                                <div class="score" style="transform: scale(0.8);">{{ filterGoalLength(missed) ? Math.round((filterGoalLength(missed) * 100) / filterGoalLength(histories)) : 0 }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="progressTracking.public_settings ? progressTracking.public_settings.element_color : '#2f7eed'" :stroke-dasharray="115" :stroke-dashoffset="115 - (filterGoalLength(missed) ? (Math.round((filterGoalLength(missed) * 100) / filterGoalLength(histories)) * 1.15) : 0)"/>
                            </svg>
                        </div>
                    </div>
                    <div class="result_wpr">
                        <div class="history_tbl">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="wx-200">Date</th>
                                        <!-- <th v-if="progressTracking.data_settings.has_weight || progressTracking.data_settings.has_body_fat || progressTracking.data_settings.has_circumference">Week</th> -->
                                        <th>Status</th>
                                        <th class="wx-75">Entry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(miss, h) of missed" :key="h">
                                        <td>{{ moment.utc(miss.created_at).local().format('MM/DD/YYYY') }}</td>
                                        <!-- <td v-if="progressTracking.data_settings.has_weight || progressTracking.data_settings.has_body_fat || progressTracking.data_settings.has_circumference">Week {{ history.week ? history.week : 1 }}</td> -->
                                        <td v-if="miss.is_goal">Submitted Goals</td>
                                        <td v-else>
                                            <span v-if="miss.is_submitted && miss.is_edited">Edited After Submission</span>
                                            <span v-else-if="miss.is_submitted && moment.duration(moment(miss.updated_at).diff(miss .scheduled_at)).asHours() >= 23">Submitted Late</span>
                                            <span v-else-if="miss.is_submitted">Submitted On Time</span>
                                            <span v-else-if="!miss.is_submitted && moment.duration(moment().diff(miss.scheduled_at)).asHours() >= 6" >Missed</span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <button @click="viewHistory(miss)"><i class="fa fa-search"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <history-preview v-model="previewHistory" :history="selectedHistory" :progress-tracking="progressTracking" />
            </div>
            <div class="report_container" v-else>
                <h3 class="no_content">Contact has not set the goal yet</h3>
            </div>
        </div>
        <div v-else>
            <div class="report_container">
                <advanced-tracking-report :tracking="progressTracking" :contact-relation="contactRelation" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const HistoryPreview = defineAsyncComponent(() => import('@/pages/progress-tracking/components/HistoryPreview'))
    const AdvancedTrackingReport = defineAsyncComponent(() => import('@/pages/progress-tracking/components/AdvancedTrackingReport'))

    import moment from 'moment'

    export default {
        name: 'Progress Report',

        data () {
            return {
                contact: {},
                currentProgress: {},
                histories: [],
                questionSections: [],
                submittedGoal: 0,
                moment,
                previewImagePanel: false,
                selectdImageIndex: 0,
                availableImages: '',
                selectedHistory: {},
                previewHistory: false,
                completed: [],
                missed: [],
            };
        },

        props: {
            progressTracking: Object,
            contactRelation: Object,
        },

        components: {
            HistoryPreview,
            AdvancedTrackingReport,
        },

        watch: {
            contactRelation: {
                handler (relation) {
                    const vm = this;
                    const params = { contact_id: relation.contact_id ? relation.contact_id : relation.id, assign_id: relation.deleted_at ? relation.id : '', progress_tracking_id: vm.progressTracking.id };

                    vm.getContactCurrentProgress(params).then((report) => {
                        if (report) {
                            vm.getCurrentProgressProof(params);
                        }
                    });
                },
                deep: true
            },

            contactCurrentProgress (report) {
                const vm = this;

                vm.contact          = report.contact;
                vm.currentProgress  = report.currentProgress;
                vm.histories        = report.histories;
                vm.questionSections = report.questionSections;
                vm.submittedGoal    = report.has_goal;
                vm.completed        = report.completed;
                vm.missed           = report.missed;
            },
        },

        computed: mapState({
            submittedProofs: state => state.progressTrackingModule.submittedProofs,
            contactCurrentProgress: state => state.progressTrackingModule.contactCurrentProgress,
            contactCurrentProgressLoader: state => state.progressTrackingModule.contactCurrentProgressLoader,
        }),

        mounted () {
            const vm = this;
            const params = { contact_id: vm.contactRelation.contact_id ? vm.contactRelation.contact_id : vm.contactRelation.id, assign_id: vm.contactRelation.deleted_at ? vm.contactRelation.id : '', progress_tracking_id: vm.progressTracking.id };

            vm.getContactCurrentProgress(params).then((report) => {
                if (report) {
                    vm.getCurrentProgressProof(params);
                }
            });
        },

        methods: {
            ...mapActions({
                getContactCurrentProgress: 'progressTrackingModule/getContactCurrentProgress',
                getCurrentProgressProof: 'progressTrackingModule/getCurrentProgressProof',
            }),

            previewImage (image) {
                const vm = this;

                vm.previewImagePanel  = true;
                vm.availableImages    = image;
            },

            viewHistory (history) {
                const vm = this;

                vm.selectedHistory = history;
                vm.previewHistory = true;
            },

            filterGoalLength (histories) {
                return histories.filter(history => history.is_goal == 0).length;
            },
        },
    }
</script>

<style scoped>
    .report_section {
        padding: 50px 0;
    }

    .report_container {
        max-width: 1170px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .report_card {
        border: 1px solid #eaeaea;
        border-radius: 6px;
    }

    .report_card .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
    }

    .report_card .user_info {
        display: flex;
        text-align: left;
        align-items: center;
    }

    .report_card .user_info img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 10px;
        border: 1px solid #ccc;
    }

    .report_card .header h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
        margin-bottom: 5px;
    }

    .report_card .header h3 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        margin-bottom: 3px;
    }

    /* .tracking_list li {
            width: 50%;
            padding: 15px 30px;
            text-align: left;
        } */
    .tracking_list li h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
        /* margin-left: 10px; */
    }

    .tracking_list li h4 span {
        font-size: 18px;
        line-height: 23px;
        display: block;
        color: #121525;
        padding-top: 5px;
    }

    .tracking_list .score_circle {
        justify-content: flex-end;
        flex-shrink: 0;
    }

    .result_wpr {
        border-color: #eaeaea;
        margin-top: 12px;
        border-radius: 10px;
    }

    .result_wpr table thead {
        border: 0;
        border-bottom: 1px solid #ddd;
    }

    .result_wpr table th,
    .result_wpr table td,
    .result_wpr table td:first-child,
    .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
        text-align: left;
    }

    .result_wpr table th {
        background: #f5f5f5;
    }

    .progress_tbl .result_wpr table td:not(:last-child) {
        width: 22%;
    }

    .result_wpr table td label {
        font-weight: 500;
    }

    .result_wpr table td h6 {
        font-size: 11px;
        line-height: 15px;
        color: #2f7eed;
        font-weight: 500;
        text-align: right;
    }

    .result_wpr table td .status {
        height: 4px;
        border-radius: 2px;
        background: #eaeaea;
        position: relative;
    }

    .result_wpr table td .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 2px;
        background: #2f7fed;
    }

    .result_wpr table td button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 14px;
        background: #e9e9e9;
        /* background: var(--var-element-bg-color, #D4D4D4); */
        color: var(--var-element-text-color, #2C3E50);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .picture_section {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .picture_section>li {
        padding: 5px 15px;
        width: 33.333%;
    }

    .picture_section>li .section_wpr {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
    }

    .picture_section .section_wpr li:not(:last-child) {
        border-right: 1px solid #eaeaea;
    }

    .picture_section .section_wpr li {
        flex: 1;
        line-height: 0;
    }

    .picture_section .section_wpr li .section_title {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
    }

    .picture_section .section_wpr li .section_title h4 {
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        text-align: left;
        color: #121525;
    }

    .picture_section .section_wpr li .section_title h4 span {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        display: block;
        padding-top: 3px;
        color: #757575;
    }

    .picture_section .section_wpr li .section_title i {
        font-size: 12px;
        width: 25px;
        height: 25px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        margin-left: 5px;
        color: #2f7eed;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .picture_section .section_wpr li img {
        width: 100%;
    }

    .grp_wpr {
        display: flex;
        margin: 0 -10px;
    }

    .box_item {
        flex: 1 0 50%;
        padding: 5px 10px;
    }

    .grp_wpr label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
    }

    .img_wpr img {
        width: 100%;
        height: 220px;
        border-radius: 6px;
        object-fit: cover;
    }

    .img_viewer {
        position: relative;
        overflow: hidden;
    }

    .img_viewer .lightbox_btn {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -20px;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .img_viewer .download_btn {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -20px;
        top: 10px;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .img_viewer:hover .lightbox_btn {
        left: 10px;
    }

    .img_viewer:hover .download_btn {
        right: 10px;
    }

    .result_wpr table th {
        /* background: var(--var-element-bg-color, #f5f5f5); */
        background: #f5f5f5;
        /* color: var(--var-element-text-color, #121525); */
    }

    .result_wpr table thead tr th:first-child {
        border-top-left-radius: 10px;
    }

    .result_wpr table thead tr th:last-child {
        border-top-right-radius: 10px;
    }

    .tracking_list li {
        background: var(--var-element-light-color, #f9f9f9);
    }

    .result_wpr table td h6 {
        color: var(--var-element-bg-color, #2F7FED);
    }

    .result_wpr table td .status span {
        background: var(--var-element-bg-color, #2F7FED);
    }
    h3.no_content{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .content_card_wpr .section_header h2 {
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .content_card_wpr .section_header h2 label {
        font-size: 11px;
        line-height: 20px;
        border-radius: 12px;
        color: #2f7eed;
        background: #e3eefd;
        font-weight: 500;
        display: inline-block;
        padding: 0 6px;
    }

    th.wx-75 {
        width: 75px !important;
        max-width: 75px !important;
    }

    th.wx-200 {
        width: 30% !important;
        max-width: 30% !important;
    }

    @media(max-width: 767px){
        .report_section .result_wpr table th, .report_section .result_wpr table td{
            font-size: 11px;
            line-height: 15px;
            padding: 15px 10px!important;
        }
    }
</style>